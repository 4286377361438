import { useRouter } from "vue-router"

export default function setupCommons() {
  const Router = useRouter()

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  function actionHandler(e: any) {
    if (e.target.nodeName === 'A' || e.target.nodeName === 'IMG' || e.target.nodeName === 'SVG' || e.target.nodeName === 'PATH' || e.target.nodeName === 'BUTTON' || e.target.nodeName === 'UL' || e.target.closest('a') || e.target.classList.contains('TheEnd'))
      return
    Router.push(e.target.closest('.ListItem').getAttribute('data-to'))
  }

  return {
    actionHandler,
    scrollToTop
  }
}
