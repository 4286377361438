export function handleNotification(platform: any, user: any, notification: any) {
  if (notification === 'heartbeat') {
    platform.setLastRTNHeartbeat()
    return
  }

  const notificationObject = JSON.parse(notification)
  notificationObject.hasTimeoutSet = false
  notificationObject.timeoutID = null
  platform.addToast(notificationObject)
  if (notificationObject.incMessages) user.incrementMessages()
  if (notificationObject.incUnread) user.incrementUnread()
  return
}
