import localizedFormat from "dayjs/plugin/localizedFormat"
import relativeTime from "dayjs/plugin/relativeTime"
import updateLocale from "dayjs/plugin/updateLocale"
import dayjs from "dayjs"

dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)
dayjs.extend(updateLocale)

const submitLocale = {
  name: 'submit',
  weekdays: 'Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday'.split('_'),
  months: 'January_February_March_April_May_June_July_August_September_October_November_December'.split('_'),
  relativeTime: {
    future: "%s",
    past: "%s",
    s: '5s',
    m: "1m",
    mm: "%dm",
    h: "1h",
    hh: "%dh",
    d: "1d",
    dd: "%dd",
    M: "1mo",
    MM: "%dmo",
    y: "1y",
    yy: "%dy"
  },
  ordinal: (n: number) => {
    const s = ['th', 'st', 'nd', 'rd']
    const v = n % 100
    return `[${n}${(s[(v - 20) % 10] || s[v] || s[0])}]`
  }
}

dayjs.locale('submit', submitLocale)
dayjs.locale('en')

export default dayjs
